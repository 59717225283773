import React from 'react';
import Helmet from 'react-helmet';
import { Box } from 'rebass';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';

import Layout from '../components/Layout';
import { ArticleContainer } from '../components/ui/ArticleContainer';

class TagRoute extends React.Component {
  render() {
    const posts = [
      ...this.props.data.allMarkdownRemark.edges,
      ...this.props.data.allMdx.edges,
    ];
    const postLinks = posts.map((post) => (
      <li key={post.node.fields.slug}>
        <Link to={`/${post.node.fields.slug}`}>
          <div>{post.node.frontmatter.title}</div>
        </Link>
      </li>
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const siteUrl = this.props.data.site.siteMetadata.siteUrl;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} article${
      totalCount === 1 ? '' : 's'
    } avec le tag “${tag}”`;

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${tag} | ${title}`}</title>
            <link
              rel="canonical"
              href={`${siteUrl}/articles/tags/${kebabCase(tag)}`}
            />
            <meta
              property="og:url"
              content={`${siteUrl}/articles/tags/${kebabCase(tag)}`}
            />
            <meta
              name="twitter:url"
              content={`${siteUrl}/articles/tags/${kebabCase(tag)}`}
            />
            <meta name="robots" content="noindex" />
          </Helmet>
          <ArticleContainer>
            <div className="mx-auto prose prose-lg text-gray-600 prose-indigo">
              <div>
                <div>
                  <h3>{tagHeader}</h3>
                  <Box mt={3}>
                    <ul>{postLinks}</ul>
                  </Box>
                  <p>
                    <Link to="/tags">Voir tous les tags</Link>
                  </p>
                </div>
              </div>
            </div>
          </ArticleContainer>
        </div>
      </Layout>
    );
  }
}

export default TagRoute;

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
